import React, { useContext } from 'react'
import { AcervoContext } from '@solid-ui-components/Modal'
import Tabs from '@solid-ui-components/Tabs'
import { Container } from 'theme-ui'

const texts = [
	'STF',
	'Superiores',
	'TRFs',
	'TJs',
	'TRTs',
	'Admin.'
]

const styleTabs = {
	position: `relative`,
	py: [1, 1],
	'::before': {
		position: `absolute`,
		content: `" "`,
		size: `full`,
		top: 0,
		right: 0,
		zIndex: -1,
		borderRadius: `xl`,
		background: `linear-gradient(
        180deg,
        #f7f9fe 0%,
        #f4f4f8 100%
      )`
	}
}

const AcervoTabs = props => {
	const { setActiveAcervo } = useContext(AcervoContext)

	const handleChangeTab = (index, name) => {
		setActiveAcervo(index)
		props.setShouldCloseModal(true)
	}

	return (
		<Container variant='container' sx={styleTabs}>
			<Tabs tabs={texts} id={'acervo'} onChange={handleChangeTab} variant='pill' />
		</Container>
	)
}

export default AcervoTabs
