import React, { useState, useEffect, useContext } from 'react'
import { Spinner } from 'theme-ui'
import { AcervoContext } from '@solid-ui-components/Modal'
import './BrazilMap.css'
import { stateKeys, sourceIndex, entityTypes } from './de_para'
import axios from 'axios'
import Map from './Map'
import { mock } from './mock'
import Icon from '@solid-ui-components/ContentIcon'
import iconedocumento from './documento.svg'

const documentIcon = {
  "src": iconedocumento,
  "color": "beta"
}


const BrazilMap = (props) => {
  const { activeAcervo } = useContext(AcervoContext);
  const [apiData, setApiData] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [stateInfo, setStateInfo] = useState({});
  const [showModalInfo, setShowModalInfo] = useState(false);

  const searchByEntityType = (entityType, stateName) => {
    return apiData.filter(data => stateKeys[stateName].includes(data.court_code) && data.court_code.includes(entityType));
  }

  const getDataToShow = (activeTab, stateName) => {
    switch (activeTab) {
      case sourceIndex.STF:
        return apiData.filter(data => entityTypes.STF.includes(data.court_code));
      case sourceIndex.Superiores:
        return apiData.filter(data => entityTypes.Superiores.includes(data.court_code));
      case sourceIndex.TRFs:
        return searchByEntityType('TRF', stateName)
      case sourceIndex.TJs:
        return searchByEntityType('TJ', stateName)
      case sourceIndex.TRTs:
        return searchByEntityType('TRT', stateName)
      case sourceIndex.Admin:
        return apiData.filter(data => entityTypes.Admin.includes(data.court_code) || (data.court_code.includes('TITSP') && stateName.includes('São Paulo')));
      default:
        return apiData.filter(data => entityTypes.STF.includes(data.court_code));
    }
  }

  const getStateProperties = (stateName, propertyName) => {
    const values = stateKeys[stateName];
    return values.some(value => value.includes(propertyName));
  }

  const iterateOverStates = callback => {
    let states = document.getElementsByClassName('estado');
    if (states.length > 0) {
      for (let i = 0; i < states.length; i++) {
        callback(states[i]);
      }
    } else {
      //GAMBIARRA SINISTRA -> VER UM JEITO MELHOR DE FAZER -> OS ELEMENTOS DO MAPA DEMORAM UM POUCO PARA PODEREM SER PEGOS PELO DOM
      setTimeout(() => {
        for (let i = 0; i < states.length; i++) {
          callback(states[i]);
        }
      }, 300)
    }
  }

  const clearMapColors = state => {
    state.firstChild.classList.remove('active');
    state.childNodes[1].classList.remove('active');
  }

  const fillMapColors = state => {
    //se estiver, pinta de laranja
    state.firstChild.classList.add('active')
    //pega os estados que tem formato de circulo
    let secondChild = state.childNodes[1]
    if (secondChild.className.baseVal === 'circle') {
      secondChild.classList.add('active')
    }
  }

  //pega os dados da API com a contagem
  useEffect(() => {
    // axios.get('http://35.199.109.29:8942/document/counts')
    //   .then(res => {
    //     setApiData(res.data.counts);
    //     setHasLoaded(true);
    //   })
    //   .catch(err => console.error(err));
    setApiData(mock.counts)
    setHasLoaded(true)
  }, [])

  useEffect(() => {
    const callback = state => {
      const stateName = state.getAttribute('name')
      let stateInfo = {
        name: stateName,
        data: apiData.filter(data => entityTypes.STF.includes(data.court_code))
      }
      setStateInfo(stateInfo)
      state.onclick = (e) => {
        props.setShouldCloseModal(false)
        setShowModalInfo(true)
      }
    }
    iterateOverStates(callback)
  }, [])

  useEffect(() => {
    const callback = state => {
      const stateName = state.getAttribute('name')
      state.onclick = (e) => {
        let stateInfo = {
          name: stateName,
          data: getDataToShow(activeAcervo, stateName)
        }
        setStateInfo(stateInfo)
        props.setShouldCloseModal(false)
        setShowModalInfo(true)
      }
    }
    iterateOverStates(callback)
  }, [activeAcervo, hasLoaded, stateInfo, showModalInfo])

  //colore os estados de acordo com o acervo ativo
  useEffect(() => {
    const callback = state => {
      const stateName = state.getAttribute('name')
      //verifica qual acervo está selecionado (STF, Superiores, TRFs, TJs, TRTs, Admin)
      switch (activeAcervo) {
        case sourceIndex.STF:
          clearMapColors(state)
          fillMapColors(state)
          break;
        case sourceIndex.Superiores:
          clearMapColors(state)
          fillMapColors(state)
          break;
        case sourceIndex.TRFs:
          clearMapColors(state)
          if (getStateProperties(stateName, 'TRF')) {
            fillMapColors(state)
          }
          break;
        case sourceIndex.TJs:
          clearMapColors(state)
          if (getStateProperties(stateName, 'TJ')) {
            fillMapColors(state)
          }
          break;
        case sourceIndex.TRTs:
          clearMapColors(state)
          if (getStateProperties(stateName, 'TRT')) {
            fillMapColors(state)
          }
          break;
        case sourceIndex.Admin:
          clearMapColors(state)
          fillMapColors(state)
          break;

        default:
          clearMapColors(state)
          fillMapColors(state)
          break;
      }
    }
    iterateOverStates(callback)
  }, [activeAcervo])

  return (
    <>
      {hasLoaded ?
        (
          <div className='content-container'>
            <div id='infomodal' className={showModalInfo && !props.shouldCloseModal ? ('modal-visible') : ('modal-hide')}>
              <span className='close-modal-button' onClick={() => setShowModalInfo(false)}>X</span>
              <div className='contentinfo'>
                {showModalInfo && (
                  <>
                    <span className='statename'>{stateInfo.name}</span>
                    <span className='totaldocuments'>
                      <Icon content={documentIcon} size='xs' />
                      {parseFloat(stateInfo.data?.map(info => info.document_count).reduce((acc, value) => acc + value)).toLocaleString()} documentos
                    </span>
                    {stateInfo.data?.length > 0 ? (
                      <div className='stateinfo'>
                        {
                          React.Children.toArray(
                            stateInfo.data.map(info => (
                              <span>✔ <strong>{info.court_code}:</strong> {info.document_count.toLocaleString('pt-BR')}</span>
                            ))
                          )
                        }
                      </div>
                    ) : (
                      <span>Em breve teremos os dados dessa região</span>
                    )}
                  </>
                )}
              </div>
            </div>
            <Map />
          </div>
        )
        : (
          <div>
            <Spinner size='64' color='alpha' />
          </div>
        )
      }
    </>
  )
}

export default BrazilMap
