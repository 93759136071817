//STF
const STF = "STF"

//SUPERIORES
const STJ = "STJ"
const STM = "STM"
const TCU = "TCU"
const TSE = "TSE"
const TST = "TST"
const CNJ = "CNJ"
const CSJT = "CSJT"

//TRFs
const TRF1 = "TRF1"
const TRF2 = "TRF2"
const TRF3 = "TRF3"
const TRF5 = "TRF5"
const TRF4 = "TRF4"

//TJs
const TJAC = "TJAC"
const TJAL = "TJAL"
const TJAM = "TJAM"
const TJBA = "TJBA"
const TJCE = "TJCE"
const TJDFT = "TJDFT"
const TJGO = "TJGO"
const TJMS = "TJMS"
const TJMG = "TJMG"
const TJPA = "TJPA"
const TJPR = "TJPR"
const TJPE = "TJPE"
const TJRJ = "TJRJ"
const TJRS = "TJRS"
const TJSC = "TJSC"
const TJSP = "TJSP"

//TRTs
const TRT1 = "TRT1"
const TRT2 = "TRT2"
const TRT3 = "TRT3"
const TRT8 = "TRT8"
const TRT12 = "TRT12"
const TRT13 = "TRT13"
const TRT15 = "TRT15"
const TRT16 = "TRT16"
const TRT24 = "TRT24"

//ADMIN
const TITSP = "TITSP"
const CARF = "CARF"
const CARF1CC = "CARF-1CC"
const CARF2CC = "CARF-2CC"
const CARF3CC = "CARF-3CC"
const CSRFCARF = "CSRF-CARF"

export const stateKeys = {
	"BR": [
		CARF,
		CARF1CC,
		CARF2CC,
		CARF3CC,
		CNJ,
		CSJT,
		CSRFCARF,
		STF,
		STJ,
		STM,
		TCU,
		TSE,
		TST
	],
	"Acre": [
		TJAC,
		TRF1,
	],
	"Alagoas": [
		TJAL,
		TRF5,
	],
	"Amapá": [
		TRF1,
		TRT8,
	],
	"Amazonas": [
		TJAM,
		TRF1,
	],
	"Bahia": [
		TJBA,
		TRF1,
	],
	"Ceará": [
		TJCE,
		TRF5,
	],
	"Distrito Federal": [
		TJDFT,
		TRF1,
	],
	"Espírito Santo": [
		TRF2
	],
	"Goiás": [
		TJGO,
		TRF1,
	],
	"Maranhão": [
		TRF1,
		TRT16,
	],
	"Mato Grosso": [
		TRF1,
	],
	"Mato Grosso do Sul": [
		TJMS,
		TRF3,
		TRT24,
	],
	"Minas Gerais": [
		TJMG,
		TRF1,
		TRT3,
	],
	"Pará": [
		TJPA,
		TRF1,
		TRT8,
	],
	"Paraíba": [
		TRF5,
		TRT13,
	],
	"Paraná": [
		TJPR,
		TRF4,
	],
	"Pernambuco": [
		TJPE,
		TRF5,
	],
	"Piauí": [
		TRF1,
	],
	"Rio de Janeiro": [
		TJRJ,
		TRF2,
		TRT1,
	],
	"Rio Grande do Norte": [
		TRF5,
	],
	"Rio Grande do Sul": [
		TJRS,
		TRF4,
	],
	"Rondônia": [
		TRF1,
	],
	"Roraima": [
		TRF1,
	],
	"Santa Catarina": [
		TJSC,
		TRF4,
		TRT12,
	],
	"São Paulo": [
		TJSP,
		TITSP,
		TRF3,
		TRT15,
		TRT2,
	],
	"Sergipe": [
		TRF5,
	],
	"Tocantins": [
		TRF1,
	]
}

export const sourceIndex = {
	STF: 0,
	Superiores: 1,
	TRFs: 2,
	TJs: 3,
	TRTs: 4,
	Admin: 5
}

export const entityTypes = {
	STF: [
		STF
	],
	Superiores: [
		STJ,
		STM,
		TCU,
		TSE,
		TST,
		CNJ,
		CSJT
	],
	Admin: [
		CARF,
		CARF1CC,
		CARF2CC,
		CARF3CC,
		CSRFCARF,
	]
}