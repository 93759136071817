/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import logomarca from '../assets/logomarca-juit-zebra.svg'

export default {
  heroContainer: {
    position: `relative`,
    py: [1, 3],
    '::before': {
      content: `" "`,
      width: `110%`,
      height: ['311px', `240px`],
      position: `absolute`,
      top: `-270px`,
      zIndex: -1,
      background: t => `linear-gradient(
          ${t.colors.alpha} 60%,
          ${t.colors.beta} 100%
        )`,
      backgroundSize: `100%`,
      backgroundPosition: `650px bottom`
    },
    '::after': {
      content: `" "`,
      position: `absolute`,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -45%)`,
      zIndex: -1,
      size: 'full',
      maxWidth: 600,
      maxHeight: 600,
      background: `url(${logomarca}) no-repeat center center`,
      backgroundSize: `contain`,
      opacity: 0.1
    }
  }
}
